import { BaseService, IBaseModel, srv, createSingleton, AppConfig } from '@luxms/bi-core';
import { $eid, $eidx, $esid } from '../../src/libs/imdas/list';
import isEqual from 'lodash/isEqual';
import { lang, makePlainConfig } from '../../src/utils/utils';
import buildUrl from '../../src/utils/buildUrl';
import { IRawDataset } from '@luxms/bi-core/dist/repositories/adm';
import axios, { AxiosRequestConfig } from 'axios';


const DatasetsService = srv.adm.DatasetsService;
const TopicsService = srv.adm.TopicsService;
const TopicDatasetMapsService = srv.adm.TopicDatasetMapsService;

export interface ITopic {
  id: number;
  srt: number;
  title: string;
  datasets: ILocalDataset[];
  config: any;
  images?: any;
}

export interface IDatasetsByTopicsModel extends IBaseModel {
  topics: ITopic [];
  flattenModel: (ILocalDataset | ITopic)[];
  datasets: ILocalDataset[];
}

export interface ILocalDataset extends IRawDataset {
  href: string;
}


export class DatasetsByTopicsService extends BaseService<IDatasetsByTopicsModel> {
  constructor() {
    super({
      loading: true,
      error: null,
      topics: [],
      flattenModel: [],
      datasets: [],
    });

    DatasetsService.getInstance().subscribeUpdates(this._reload);
    TopicsService.getInstance().subscribeUpdates(this._reload);
    TopicDatasetMapsService.getInstance().subscribeUpdates(this._reload);

    this._reload();
  }

  protected _dispose() {
    DatasetsService.getInstance().unsubscribe(this._reload);
    TopicsService.getInstance().unsubscribe(this._reload);
    TopicDatasetMapsService.getInstance().unsubscribe(this._reload);
    super._dispose();
  }

  private _modifiedDatasets = (datasetsModel) => {
    const localDatasets: ILocalDataset[] = datasetsModel.filter(dataset => !!dataset.schema_name).map(ds => {
      let uiCfg = {};

      if (ds.ui_cfg) {
        uiCfg = ds.ui_cfg;
      } else if (ds.config) {
        uiCfg = makePlainConfig(ds.config || {});
      }

      return ({...ds, href: buildUrl(uiCfg, ds.schema_name)});
    });

    return localDatasets;
  }

  private _reload = () => {
    const datasetsModel = DatasetsService.getInstance().getModel();
    const topicsModel = TopicsService.getInstance().getModel();
    const topicDatasetMaps = TopicDatasetMapsService.getInstance().getModel();

    if (datasetsModel.error || topicsModel.error || topicDatasetMaps.error) {
      return this._updateWithError(datasetsModel.error || topicsModel.error || topicDatasetMaps.error);
    }

    if (datasetsModel.loading || topicsModel.loading || topicDatasetMaps.loading) {
      return this._updateWithLoading();
    }

    const datasets = this._modifiedDatasets(datasetsModel);

    const topicWithoutDatasets = {
      id: -1,
      srt: 1,
      title: lang('without_group'),
      config: {},
      datasets: datasets.filter(dataset => !topicDatasetMaps.find(tdm => tdm.dataset_id === dataset.id)).sort((a, b) => a.srt - b.srt),
    };

    const topics = [topicWithoutDatasets].concat(topicsModel.map(topic => ({
      ...topic,
      datasets: $esid(datasets, topicDatasetMaps.filter(tdm => tdm.topic_id === topic.id).map(tdm => tdm.dataset_id)).sort((a, b) => a.srt - b.srt),
    })));

    const flattenModel = this._flattenModel(topics);

    this._updateWithData({topics, flattenModel, datasets});
  }

  // не удалось отследить правильно ли работает этот код - поскольку зависящие - не живут одновременно в течении одной сессии
  public async saveDataset(datasetID, info, images, $config, datasetGroups) {
    let topics = [...this._model.topics];

    // массив индексов целевых и нецелевых топиков
    let targetTopicsIndexes: number[] = [];
    let noTargetTopicsIndexes: number[] = [];

    // формируем массив индексов целевых и нецелевых топиков
    if (datasetGroups.length) {
      // если на входе имеем id топиков

      // то сначала отбираем нецелевые топики из общего массива топиков
      topics.forEach((topic, idx) => {
        datasetGroups.forEach(groupID => {
          if (topic.id != groupID && !noTargetTopicsIndexes.includes(idx)) {
            noTargetTopicsIndexes.push(idx);
          }
        });
      });

      // потом отбираем целевые топики из общего массива топиков
      topics.forEach((topic, idx) => {
        datasetGroups.forEach(groupID => {
          if (topic.id == groupID) {
            targetTopicsIndexes.push(idx);
          }
        });
      });

    } else {
      // если на входе id топиков не получили

      // это значит что целевым индексом топика является topiс No Group (у которого id === -1)
      const index = topics.findIndex(topic => topic.id === -1);
      targetTopicsIndexes = [(index !== -1 ? index : 0)]; // если вдруг такого не будет (что недопустимо) - то выставим просто 0

      // а нецелевыми индексами топиков являются все те которые не No Group. (у которых id !== -1)
      topics.forEach((topic, idx) => {
        if (topic.id !== -1) noTargetTopicsIndexes.push(idx);
      });
    }

    // удаляем датасет из нецелевых топиков
    noTargetTopicsIndexes.forEach(topicIndex => {
      const datasetIndex = topics[topicIndex].datasets.findIndex(dataset => dataset.id == datasetID);
      if (datasetIndex !== -1) topics[topicIndex].datasets.splice(datasetIndex, 1);
    });

    // обратываем датасет в целевых топиках (если такие имеются) иначе добавляем
    targetTopicsIndexes.forEach(topicIndex => {
      const datasetIndex = topics[topicIndex].datasets.findIndex(dataset => dataset.id == datasetID);

      if (datasetIndex !== -1) {
        const dataset = topics[topicIndex].datasets[datasetIndex];

        topics[topicIndex].datasets[datasetIndex] = {
          ...dataset,
          title: info.title,
          description: info.description,
        };
      } else {
        topics[topicIndex].datasets.push({
          title: info.title,
          description: info.description,
          srt: 999999,
          config: {},
        });
      }
    });

    this._updateWithData({topics});

    try {
      const datasetsService = DatasetsService.getInstance();
      await datasetsService.whenReady();
      const datasets = datasetsService.getModel();

      let dataset = datasets.find(ds => String(ds.schema_name) === String(datasetID) || String(ds.id) === String(datasetID) || String(ds.guid) === String(datasetID));

      dataset = await datasetsService.save({
        id: dataset?.id,
        title: info.title,
        description: info.description,
      });

      const topicDatasetMapsService = TopicDatasetMapsService.getInstance();
      await topicDatasetMapsService.whenReady();
      let topicDatasetMaps = topicDatasetMapsService.getModel();

      let tdmsIDs = datasetGroups.map(groupID => +groupID);

      let tdms = datasetGroups.length
          ? tdmsIDs.map(tdmID => topicDatasetMaps.find(tdm => tdm.topic_id == tdmID && tdm.dataset_id == dataset.id))
          : topicDatasetMaps.filter(tdm => tdm.dataset_id == dataset.id);

      if (datasetGroups.length != 0) {
        await topicDatasetMapsService.updateMany(tdms.map(({id, topic_id, dataset_id}) => ({
          id,
          topic_id,
          dataset_id
        })));
      } else {
        for (let i = 0; i < tdms.length; i++) {
          await topicDatasetMapsService.remove(tdms[i].id);
        }
      }
      return dataset;
    } catch (e) {
      this._reload();
      return e;
    }
  }

  public moveDataset(currentTopicID, currentDataset, targetTopicID, targetDataset): Promise<any> {
    let topics = [...this._model.topics];

    const currentTopicIndex = topics.findIndex(topic => topic.id === currentTopicID);
    const targetTopicIndex = topics.findIndex(topic => topic.id === targetTopicID);
    const currentDatasetIndex = topics[currentTopicIndex].datasets.findIndex(dataset => dataset.id === currentDataset.id);
    const currentTopic = topics[currentTopicIndex];
    const targetTopic = topics[targetTopicIndex];

    currentTopic.datasets = [...currentTopic.datasets];
    targetTopic.datasets = [...targetTopic.datasets];

    const datasetIsExistInTargetTopic = targetTopic.datasets.find(dataset => dataset.id === currentDataset.id);

    if (currentTopicIndex === -1 || targetTopicIndex === -1) console.error('currentTopic or targetTopic not found in topics array');

    if (targetDataset) {
      const targetDatasetIndex = targetTopic.datasets.findIndex(dataset => dataset.id === targetDataset.id);

      if (currentTopicID == targetTopicID) {
        currentTopic.datasets.splice(currentDatasetIndex, 1);
        targetTopic.datasets.splice(targetDatasetIndex, 0, currentDataset);
      } else {
        currentTopic.datasets.splice(currentDatasetIndex, 1);
        if (!datasetIsExistInTargetTopic) targetTopic.datasets.push(currentDataset);
      }

    } else if (currentTopicID !== targetTopicID) {
      currentTopic.datasets.splice(currentDatasetIndex, 1);
      if (!datasetIsExistInTargetTopic) targetTopic.datasets.push(currentDataset);
    }

    // currentTopic.datasets.forEach((dataset, index) => currentTopic.datasets[index] = dataset.srt = index + 1);
    targetTopic.datasets.forEach((dataset, index) => targetTopic.datasets[index] = {...dataset, srt: index + 1});

    this._updateWithData({topics});

    return this._freezeUpdates(async () => {

      try {
        const topicDatasetMapsService = TopicDatasetMapsService.getInstance();
        const topicDatasetMaps = topicDatasetMapsService.getModel();

        if (currentTopicID !== targetTopicID) {
          let tdm = topicDatasetMaps.find(tdm => tdm.topic_id === currentTopicID && tdm.dataset_id === currentDataset.id);
          const hasSameDatasetOnTarget = !!topicDatasetMaps.find(tdm => tdm.topic_id === targetTopicID && tdm.dataset_id === currentDataset.id);

          if (tdm && targetTopicID === -1) {                                                        // Перемещаем в "Без группы"
            await topicDatasetMapsService.remove(tdm.id);
          } else if (tdm && hasSameDatasetOnTarget) {                                               // Такой датасет уже есть в группе, куда перемещаем
            await topicDatasetMapsService.remove(tdm.id);
          } else if (tdm) {
            await topicDatasetMapsService.updateOne(tdm.id, {topic_id: targetTopicID});
          } else {
            await topicDatasetMapsService.create({topic_id: targetTopicID, dataset_id: currentDataset.id});
          }
        }

        await DatasetsService.getInstance().updateMany(targetTopic.datasets.map(({id, srt}) => ({id, srt})));

      } catch (err) {
        debugger;
        this._reload();
      }
    });
  }

  public async removeDataset(id, schema_name, guid) {
    try {
      const topics = [...this._model.topics];

      let topic = topics.find(topic => {
        return topic.datasets.find(ds => String(ds.id) === String(id) || String(ds.schema_name) === String(schema_name) || String(ds.guid) === String(guid));
      });

      let dataset = topic
          ? topic.datasets.find(ds => String(ds.id) === String(id) || String(ds.schema_name) === String(schema_name) || String(ds.guid) === String(guid))
          : null;

      const datasetsService = DatasetsService.getInstance();
      if (dataset && dataset.id && dataset.id != 0) {
        await datasetsService.remove(dataset.id).catch((e) => {
          throw new Error(e);
        });
      }
    } catch (e) {
      throw new Error(e);
    }
  }

  public async cleanDataset(id: number): Promise<any> {
    const method = 'DELETE';
    const requestUrl = `/api/dataset/${id}/data`;
    const url = AppConfig.fixRequestUrl(requestUrl);

    const requestParams: AxiosRequestConfig = {
      method,
      url,
      headers: {
        'Content-Type': 'application/json',
      }
    };
    return axios(requestParams);
  }

  public moveTopic(currentTopicID, targetTopicID) {
    if (targetTopicID != -1) {

      let topics = [...this._model.topics];

      const currentTopicIndex = topics.findIndex(topic => topic.id == currentTopicID);
      const targetTopicIndex = topics.findIndex(topic => topic.id == targetTopicID);
      const currentTopic = topics[currentTopicIndex];

      topics.splice(currentTopicIndex, 1);
      topics.splice(targetTopicIndex, 0, currentTopic);
      topics.forEach((topic, index) => topics[index] = {...topic, srt: index + 1});

      this._updateWithData({topics});

      try {
        // применяем изменения, дергая методы сервиса, которые уже сохранят данные на сервер
        TopicsService.getInstance().updateMany(topics.filter(topic => topic.id !== -1).map(({id, srt}) => ({id, srt})));
      } catch (err) {
        this._reload();
      }
    }
  }

  public renameTopic(topicId: number, title: string) {
    let topics = [...this._model.topics];

    topics = topics.map(item => {
      if (item.id == topicId) item.title = title;
      return item;
    });

    this._updateWithData({topics});

    try {
      const topicsModel = TopicsService.getInstance();
      topicsModel.updateOne(topicId, {title});
    } catch (err) {
      this._reload();
    }
  }

  public removeTopic(topicId) {
    let topics = [...this._model.topics];
    topics = topics.filter(item => item.id != topicId);

    this._updateWithData({topics});

    try {
      const topicsModel = TopicsService.getInstance();
      topicsModel.remove(topicId);
    } catch (err) {
      this._reload();
    }
  }

  public addTopic(topic) {
    let topics = [...this._model.topics];
    topics = topics.concat([topic]);
    this._updateWithData({topics});

    try {
      const topicsModel = TopicsService.getInstance();
      topicsModel.create({title: topic.title});
    } catch (err) {
      this._reload();
    }
  }

  public _flattenModel(array: any[]): any[] {
    const items = [];

    array.forEach(topic => {
      items.push(topic);
      topic.datasets.forEach(dataset => items.push(dataset));
    });

    return items;
  }

  public filterFlattenModel(searchValue: string): void {

    if (!!searchValue) {
      const resultItems = [];
      this._model.topics.forEach((topic) => {
        const datasets = topic.datasets.filter(d => d.title.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1);
        if (datasets.length) {
          resultItems.push(topic);
          datasets.forEach(ds => resultItems.push(ds));
        }
      });

      if (!isEqual(resultItems, this._model.flattenModel)) this._updateWithData({flattenModel: resultItems});
    } else {
      let items = this._flattenModel(this._model.topics);
      if (!isEqual(items, this._model.flattenModel)) this._updateWithData({flattenModel: items});
    }
  }

  public onItemsChange(items: any): void {
    const sourceList = this._model.topics;
    const changedList: any[] = [];

    let sourceTopic: any = null;
    let targetTopic: any = null;
    let matchedDataset: any = null;

    items.forEach((topic: any, index: number) => {
      if (topic.datasets) {
        let currentDatasets = [];

        for (let i = index + 1; i < items.length; i++) {
          if (items[i].datasets) break;
          else currentDatasets.push(items[i]);
        }

        changedList.push({...topic, datasets: currentDatasets});
      }
    });

    sourceList.forEach(topic => {
      let matchedTopic: any = changedList.find(item => topic.id === item.id);
      let cleanTopic: boolean = isEqual(topic, matchedTopic);

      if (!cleanTopic) {
        if (topic.datasets.length === matchedTopic.datasets.length) { // на месте драг произошел
          sourceTopic = targetTopic = matchedTopic;
        } else if (topic.datasets.length > matchedTopic.datasets.length) { // перетащили в другой топик
          matchedDataset = topic.datasets.filter((dataset: any) => !matchedTopic.datasets.some((ds: any) => ds.id === dataset.id))[0];
          sourceTopic = matchedTopic;
        } else {
          targetTopic = matchedTopic;
        }
      }
    });

    targetTopic?.datasets.forEach((dataset: any, index: number) => dataset.srt = index + 1);

    return this._freezeUpdates(async () => {
      try {
        const topicDatasetMapsService = TopicDatasetMapsService.getInstance();
        const topicDatasetMaps = topicDatasetMapsService.getModel();

        if (matchedDataset && (sourceTopic.id !== targetTopic.id)) {
          let tdm = topicDatasetMaps.find(tdm => tdm.topic_id === sourceTopic.id && tdm.dataset_id === matchedDataset.id);
          const hasSameDatasetOnTarget = !!topicDatasetMaps.find(tdm => tdm.topic_id === targetTopic.id && tdm.dataset_id === matchedDataset.id);

          if (tdm && targetTopic.id === -1) {                                                        // Перемещаем в "Без группы"
            await topicDatasetMapsService.remove(tdm.id);
          } else if (tdm && hasSameDatasetOnTarget) {                                               // Такой датасет уже есть в группе, куда перемещаем
            await topicDatasetMapsService.remove(tdm.id);
          } else if (tdm) {
            await topicDatasetMapsService.updateOne(tdm.id, {topic_id: targetTopic.id});
          } else {
            await topicDatasetMapsService.create({topic_id: targetTopic.id, dataset_id: matchedDataset.id});
          }
        }

        await DatasetsService.getInstance().updateMany(targetTopic.datasets.map(({id, srt}) => ({id, srt})));

      } catch (err) {
        debugger;
        this._reload();
      }
    });
  }

  public static getInstance: () => DatasetsByTopicsService = createSingleton<DatasetsByTopicsService>(() => new DatasetsByTopicsService(), '__datasetsByTopicsService');
}
