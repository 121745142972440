import React from 'react';
import { IMainToolbarVM, MainToolbarVC } from '../../view-controllers/panels/MainToolbarVC';
import { MainToolbar } from '../panels/MainToolbar/MainToolbar';
import { lang } from '../../utils/utils';
import {AppConfig, AuthenticationService, srv} from '@luxms/bi-core';
import { DsShellHeaderLogo } from './DsShellHeaderLogo';
import useService from '../useService';
import {ProviderService} from "../../services/ProviderService";
const skin: any = require('../../skins/skin.json');


interface IDsShellHeaderProps {
  schema_name: string;
  dsTitle: string;
  dsDescription: string;
  dsUrl: string;
}

const Logo = () => {
  const provider = useService<ProviderService>(ProviderService);
  const dsRes = useService<srv.ds.ResourcesService>(srv.ds.ResourcesService, 'ds_res');
  if (provider.error || provider.loading || dsRes.loading) return null;
  let image = provider.image;
  if (dsRes.find(r => r.alt_id === 'logo.svg')) image = AppConfig.fixRequestUrl('/srv/resources/ds_res/logo.svg');
  else if (dsRes.find(r => r.alt_id === 'logo.png')) image = AppConfig.fixRequestUrl('/srv/resources/ds_res/logo.png');

  return <img className="Provider__Logo provider-logo img-thumbnail" src={image}/>;
};
export const DsShellHeader = React.memo(({schema_name}: IDsShellHeaderProps) => {
  const mainToolbar = useService<MainToolbarVC>(MainToolbarVC, schema_name);

  const onPressSignOut = async () => {
    await AuthenticationService.signOut();
    window.location.hash = '#';
  };

  const title = skin.datasetLogoTitle || AppConfig.getProjectTitle() || lang('LuxmsBI');

  return (
    <aside className="DsShellHeader">
      <header className="DsShellHeader__Logo logo">
        <a className="DsShellHeader__LogoLink" href={skin.logoLink ? skin.logoLink.slice(1, -1) : '#/ds/'} >
          <DsShellHeaderLogo schema_name={'ds_res'} title={title}/>
        </a>
      </header>

      <section className="DsShellHeader__Toolbar toolbar">
        <MainToolbar {...mainToolbar}/>
      </section>

      <footer className="DsShellHeader__Logout btn-logout authorized" onClick={onPressSignOut}>
        <span className="DsShellHeader__LogoutIcon bi-icon light">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
               x="0px" y="0px" width="22px" height="18px"
               viewBox="0 0 22 18" enableBackground="new 0 0 22 18">
            <path d="M2,15.215V2.785C2,2.353,2.353,2,2.785,2H12V0H2.785C1.249,0,0,1.249,0,2.785v12.43C0,16.751,1.249,18,2.785,18H12v-2 H2.785C2.353,16,2,15.647,2,15.215z"/>
            <polygon points="21.05,8.891 14.788,3.738 14.78,7 10,7 10,11 14.771,11 14.763,13.984"/>
          </svg>
        </span>

        <span className="DsShellHeader__LogoutText text" >{lang('log_out')}</span>
      </footer>
    </aside>);
});
