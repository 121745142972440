exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/*!\n    common variables/functions/settings\n    can be overrided via skin.json\n */.DsShellHeaderLogo__Image{flex:1 1 auto;text-align:center;display:flex;height:34.5px}div.DsShellHeaderLogo__Image>svg{height:100%}.DsShellHeaderLogo__Title{font-size:22px;line-height:46px;text-overflow:clip;padding-left:10px;white-space:pre-wrap;color:#f05045}@media(max-width: 480px){.DsShellHeaderLogo__Title{display:none}}", ""]);

// exports
exports.locals = {
	"hasDatasetsList": "false",
	"hasFullMainToolbar": "false",
	"mainFontFamily": "\"Golos UI\"",
	"mainFontSize": "16px",
	"mainColor": "var(--color6)",
	"font": "16px \"Golos UI\",\"Helvetica Neue\",\"Helvetica\",\"Arial\",sans-serif"
};