import * as React from 'react';
import { AppConfig, srv } from '@luxms/bi-core';
import './DsShellHeaderLogo.scss';
const skin: any = require('../../skins/skin.json');


// TODO: move to file, make react+SVG
const DefaultLogo = ({title}: {title: string}) => (
  <>
    <img className="DsShellHeaderLogo__Image" src={skin.datasetLogo ? skin.datasetLogo.slice(1, -1) : 'assets/logo/logo.svg'}/>
    <span className="DsShellHeaderLogo__Title" >{title}</span>
  </>);


interface IDsShellHeaderLogoProps {
  schema_name: string;
  title?: string;
}


export class DsShellHeaderLogo extends React.Component<IDsShellHeaderLogoProps> {
  public state: {
    loading: boolean;
    svgContent: string | null;
  } = {
    loading: true,
    svgContent: null,
  };
  private _resourcesService: srv.ds.ResourcesService | null = null;

  public componentDidMount() {
    if (this.props.schema_name) {
      this._resourcesService = srv.ds.ResourcesService.createInstance(this.props.schema_name);
      this._resourcesService.subscribeUpdatesAndNotify(this._onResourcesServiceUpdated);
    }
  }

  public componentWillUnmount() {
    if (this._resourcesService) {
      this._resourcesService.unsubscribe(this._onResourcesServiceUpdated);
      this._resourcesService.release();
      this._resourcesService = null;
    }
  }

  public componentDidUpdate(prevProps: Readonly<IDsShellHeaderLogoProps>, prevState: Readonly<{}>, snapshot?: any) {
    if (this.props.schema_name !== prevProps.schema_name) {
      this.componentWillUnmount();
      this.componentDidMount();
    }
  }

  private _onResourcesServiceUpdated = (resources: typeof srv.ds.ResourcesService.MODEL) => {
    if (resources.loading && !resources.error) {
      this.setState({loading: true});
      return;
    }

    // TODO: use also png format
    if (resources.find(r => r.alt_id === 'logo.svg')) {
      const {schema_name} = this.props;
      // TODO: make service with resources content
      fetch(AppConfig.fixRequestUrl(`/srv/resources/${schema_name}/logo.svg`))
        .then(resp => resp.text())
        .then(content => {
          this.setState({loading: false, svgContent: content});
        });
    } else {
      this.setState({loading: false, svgContent: null});
    }
  };

  public render() {
    const {title} = this.props;
    const {loading, svgContent} = this.state;

    if (loading) {
      // TODO: add some loading image?
      return (
        <>
          <span className="DsShellHeaderLogo__Title" >{title}</span>
        </>);
    }

    if (svgContent) {
      return (
        <>
          <div className="DsShellHeaderLogo__Image" dangerouslySetInnerHTML={{ __html: svgContent }} ></div>
          <span className="DsShellHeaderLogo__Title" >{title}</span>
        </>);
    }

    return <DefaultLogo title={title}/>;
  }
}
