/**
 * @file Manages configuration of stoplights
 */

import isNumber from 'lodash/isNumber';
import isObject from 'lodash/isObject';
import { fixColorPairEx } from './ColorPair';
import { coloring } from '../utils/utils';
import { INVALID_COLOR_PAIR } from './color-resolvers';
import { matrixMax, matrixMin, vectorMax, vectorMin } from '../data-manip/data-utils';
import { clamp, interpolateValue } from '../utils/math';
import { IStoplights, tables } from '../defs/bi';
const skin: any = require('../skins/skin.json');

function makeXLStoplights(c0: string, c50: string | undefined, c100: string): tables.IVizelConfigDisplayStoplight {
  const p0 = {
    value: 'max',
    color: c0,
    bgColor: c0,
  };
  const p50 = {
    value: 'percentile(50)',
    color: c50,
    bgColor: c50,
  };
  const p100 = {
    value: 'min',
    color: c100,
    bgColor: c100,
  };
  return {
    points: c50 ? [p0, p50, p100] : [p0, p100],
  };
}

const XL_RED = skin.XL_COLORS != undefined && skin.XL_COLORS.XL_RED != undefined ? skin.XL_COLORS.XL_RED : '#ee676e';
const XL_YELLOW = skin.XL_COLORS != undefined && skin.XL_COLORS.XL_YELLOW != undefined ? skin.XL_COLORS.XL_YELLOW : '#faed84';
const XL_GREEN = skin.XL_COLORS != undefined && skin.XL_COLORS.XL_GREEN != undefined ? skin.XL_COLORS.XL_GREEN : '#6ec079';
const XL_WHITE = skin.XL_COLORS != undefined && skin.XL_COLORS.XL_WHITE != undefined ? skin.XL_COLORS.XL_WHITE : '#fcfcff';
const XL_BLUE = skin.XL_COLORS != undefined && skin.XL_COLORS.XL_BLUE != undefined ? skin.XL_COLORS.XL_BLUE : '#6588c6';


const XL_PREDEFINES: {[key: string]: tables.IVizelConfigDisplayStoplight} = {
  XL_GREEN_YELLOW_RED: makeXLStoplights(XL_GREEN, XL_YELLOW, XL_RED),
  XL_RED_YELLOW_GREEN: makeXLStoplights(XL_RED, XL_YELLOW, XL_GREEN),
  XL_GREEN_WHITE_RED: makeXLStoplights(XL_GREEN, XL_WHITE, XL_RED),
  XL_RED_WHITE_GREEN: makeXLStoplights(XL_RED, XL_WHITE, XL_GREEN),

  XL_BLUE_WHITE_RED: makeXLStoplights(XL_BLUE, XL_WHITE, XL_RED),
  XL_RED_WHITE_BLUE: makeXLStoplights(XL_RED, XL_WHITE, XL_BLUE),
  XL_WHITE_RED: makeXLStoplights(XL_WHITE, undefined, XL_RED),
  XL_RED_WHITE: makeXLStoplights(XL_RED, undefined, XL_WHITE),

  XL_GREEN_WHITE: makeXLStoplights(XL_GREEN, undefined, XL_WHITE),
  XL_WHITE_GREEN: makeXLStoplights(XL_WHITE, undefined, XL_GREEN),
  XL_GREEN_YELLOW: makeXLStoplights(XL_GREEN, undefined, '#fbf19c'),
  XL_YELLOW_GREEN: makeXLStoplights('#fbf19c', undefined, XL_GREEN),
};


// limit: [number, number] | {min?: number, max?:number} | number
// result: [number, number]
function _makeLimit(limit: any): [number, number] {
  let result: [number, number] = [NaN, NaN];
  if (typeof limit == 'number') {     // upper bound
    result = [0, limit];
  } else if (Array.isArray(limit)) {
    console.assert(result.length === 2);
    result = limit as any;
  } else if (isObject(limit)) {
    result = [(limit as any).min, (limit as any).max];
  } else {
    console.warn('Unknown value for stoplight.limit: ', limit);
  }
  if (result[0] == null) result[0] = -Infinity;
  if (result[1] == null) result[1] = +Infinity;
  return result;
}


export class StopLight implements IStoplight {
  public bgColor: string | null;
  public color: string | null;
  public limit: [number, number];
  public name: string;
  public title?: string;
  public colorPair: IColorPair;
  private _raw: tables.IStopLight;
  private _leftClosed: boolean;
  private _rightClosed: boolean;

  public constructor(raw: tables.IStopLight) {
    this._raw = raw;
    this._leftClosed = !(raw.leftClosed === false);
    this._rightClosed = !(raw.rightClosed === false);
    this.limit = _makeLimit(raw.limit);
    this.name = (raw.name != null) ? raw.name : '';
    this.title = (raw.title != null) ? raw.title : '';
    this.colorPair = fixColorPairEx(raw);
    this.color = this.colorPair.color;
    this.bgColor = this.colorPair.bgColor;
  }

  public hasValue(v: IValue): boolean {
    if (typeof v !== 'number') return false;
    const inf: number = (this.limit[0] === null) ? -Infinity : this.limit[0];
    const sup: number = (this.limit[1] === null) ? Infinity : this.limit[1];
    return (this._leftClosed ? inf <= v : inf < v) && (this._rightClosed ? v <= sup : v < sup);
  }

  public getColor(e: IEntity, v?: IValue): string {
    return this.colorPair.color;
  }

  public getBgColor(e: IEntity, v?: IValue): string {
    return this.colorPair.bgColor;
  }

  public getColorPair(e: IEntity, v?: IValue): IColorPair {
    return this.colorPair;
  }
}


class StopPoint implements IColorResolver, IStopPoint {
  public bgColor: string;
  public color: string;
  public value: number;
  public name: string;
  public colorPair: IColorPair;
  public priority: number;
  public width: number;
  public style: string;
  public zIndex: number;
  private _raw: tables.IStopPoint;

  public constructor(raw: tables.IStopPoint, priority: number = 1) {
    this._raw = raw;
    this.priority = priority;
    this.value = raw.value as number;
    this.name = raw.name;
    this.colorPair = fixColorPairEx(raw);
    this.color = this.colorPair.color;
    this.width = raw.width != null ? raw.width : 2;
    this.style = raw.style || 'solid';
    this.zIndex = raw.zIndex != null ? raw.zIndex : 2;
    this.bgColor = this.colorPair.bgColor;
  }

  public getColor(e: IEntity, v?: IValue): string {
    return this.color;
  }

  public getBgColor(e: IEntity, v?: IValue): string {
    return this.bgColor;
  }

  public getColorPair(e: IEntity, v?: IValue): IColorPair {
    return this.colorPair;
  }

  public hasValue(v: IValue): boolean {
    return v === this.value;
  }
}


abstract class Aggregator<T> {
  protected _values: T;
  private __min: number;
  private __max: number;
  private __sorted: number[];

  public constructor(values: T) {
    this._values = values;
  }

  public min(): number {
    return (this.__min === undefined) ? (this.__min = this._min()) : this.__min;
  }

  public max(): number {
    return (this.__max === undefined) ? (this.__max = this._max()) : this.__max;
  }

  public percent(pv: number): number {
    return interpolateValue(this.min(), pv / 100, this.max());
  }

  public percentile(pv: number): number | null {
    if (this.__sorted === undefined) {
      this.__sorted = this._sorted();
    }
    const N: number = this.__sorted.length;
    const idx = clamp(Math.floor(N * pv / 100), 0, N - 1);     // 0 <= idx < N
    const result: number = this.__sorted[idx];
    return (typeof result === 'number') ? result : null;
  }

  protected abstract _min(): number;

  protected abstract _max(): number;

  protected abstract _sorted(): number[];
}


class VectorAggregator extends Aggregator<IValue[]> {
  protected _min(): number {
    return vectorMin(this._values);
  }

  protected _max(): number {
    return vectorMax(this._values);
  }

  public _sorted(): number[] {
    return this._values.filter(v => isNumber(v)).sort((a: any, b: any) => a - b) as number[];
  }
}


class MatrixAggregator extends Aggregator<IValue[][]> {
  protected _min(): number {
    return matrixMin(this._values);
  }

  protected _max(): number {
    return matrixMax(this._values);
  }

  public _sorted(): number[] {
    return Array.prototype.concat.apply([], this._values)
                .filter(v => isNumber(v))
                .sort((a: number, b: number) => a - b) as number[];
  }
}


function parseFloatOrInf(v: string, defaultValue: number = null): number {
  if (v === '') return defaultValue;
  if (v === '∞') return Infinity;
  else if (v === '+∞') return Infinity;
  else if (v === '-∞') return -Infinity;
  const x = parseFloat(v);
  if (isNaN(x)) return defaultValue;
  return x;
}


export class Stoplights implements IStoplights {
  private _raw: tables.IVizelConfigDisplayStoplight;
  private _stopLights: StopLight[] = [];
  private _stopPoints: StopPoint[] = [];
  private _constants: {[v: string]: IColorPair} = {};
  // private _values: IValue[] = null;
  private _aggregator: VectorAggregator | MatrixAggregator | null = null;

  private constructor(raw: tables.IVizelConfigDisplayStoplight) {
    if (typeof raw === 'string') {              // predefined XL
      raw = XL_PREDEFINES[raw as string];
    }
    this._raw = isObject(raw) ? raw : {};
  }

  public getLights() {
    return this._stopLights;
  }

  public getPoints() {
    return this._stopPoints;
  }

  private init(): void {
    if (Array.isArray(this._raw.lights)) {
      this._stopLights = this._raw.lights.map((light: tables.IStopLight) => {
        // TODO: parse formulaes on range
        return new StopLight(light);
      });
    }

    if (Array.isArray(this._raw.points)) {
      this._stopPoints = this._raw.points.map((point: tables.IStopPoint) => {
        const [value, priority]: [number, number] = this._parseValueFormula(point.value);
        if (!isNumber(value) || isNaN(value)) {                   // could not parse formula
          // TODO: check string and add _constant
          return null;
        }
        return new StopPoint({
          ...point,
          value,
        }, priority);
      }).filter(s => s != null);
    }

    Object.keys(this._raw).forEach((formula: string) => {
      if (formula === 'lights') return;                                           // skip
      if (formula === 'points') return;                                           // skip

      const lightCfg: any = isObject(this._raw[formula]) ? this._raw[formula] : {};
      const cp: IColorPair = fixColorPairEx(this._raw[formula]);
      const title: string = this._raw[formula]['title'];

      if (formula.match(/^(\*)?([+-]?(?:∞|\d+(?:\.\d+)?))?\.\.([+-]?(?:∞|\d+(?:\.\d+)?))?(\*)?$/)) {      // range
        this._stopLights.push(new StopLight({
          title,
          ...cp,
          limit: [parseFloatOrInf(RegExp.$2 || '', -Infinity), parseFloatOrInf(RegExp.$3 || '', +Infinity)],
          leftClosed: !!RegExp.$1,
          rightClosed: !!RegExp.$4,
        }));
        return;
      }

      const [value, priority]: [number, number] = this._parseValueFormula(formula);
      if (!isNumber(value) || isNaN(value)) {                   // could not parse formula
        this._constants[formula] = cp;
        console.warn(`Unknown formula for stoplight: ${formula}`);
        return;
      }

      this._stopPoints.push(new StopPoint({
        ...lightCfg,
        ...cp,
        value,
      }, priority));
    });

    // stop points must be sorted
    this._stopPoints.sort((e1, e2) => (e1.value === e2.value) ? (e2.priority - e1.priority) : (e1.value - e2.value));
  }

  public static create(raw: tables.IVizelConfigDisplayStoplight): Stoplights {
    const stoplights = new Stoplights(raw);
    stoplights.init();
    return stoplights;
  }

  public static createWithVector(raw: tables.IVizelConfigDisplayStoplight, vector: IValue[]): Stoplights {
    const stoplights = new Stoplights(raw);
    stoplights._aggregator = new VectorAggregator(vector);
    stoplights.init();
    return stoplights;
  }

  public static createWithMatrix(raw: tables.IVizelConfigDisplayStoplight, matrix: IValue[][]): Stoplights {
    const stoplights = new Stoplights(raw);
    stoplights._aggregator = new MatrixAggregator(matrix);
    stoplights.init();
    return stoplights;
  }


  /**
   * parse string formula like "min", "percentile(...)" or exact number and return value and its priority
   *  - exact number will get priority 10
   *  - min will get priority 8
   *  - max will get priority 9   ( min == max => take max color )
   *  - function calls will get priority 5
   * @param {IValue} formula
   * @returns {[number, number]} parsed value and its priority
   * @private
   */
  private _parseValueFormula(formula: IValue): [number | null, number | null] {
    if (typeof formula === 'number') return [formula, 10];
    if (typeof formula !== 'string') return [null, 10];

    if (formula === 'min') return [this._aggregator ? this._aggregator.min() : null, 8];
    if (formula === 'max') return [this._aggregator ? this._aggregator.max() : null, 9];
    if (formula === '+∞') return [Infinity, 9];
    if (formula === '∞') return [Infinity, 9];
    if (formula === '-∞') return [-Infinity, 9];
    if (!isNaN(formula as any)) return [parseFloat(formula as string), 10];                                   // simple number test
    if (formula.match(/^([+-]?(?:∞|\d+(?:\.\d+)?))$/)) return [parseFloatOrInf(RegExp.$1), 10];               // other number test
    if (formula.match(/^percent\((\d+)\)$/)) return [this._aggregator ? this._aggregator.percent(parseInt(RegExp.$1)) : null, 5];
    if (formula.match(/^percentile\((\d+)\)$/)) return [this._aggregator ? this._aggregator.percentile(parseInt(RegExp.$1)) : null, 5];

    return [null, 0];
  }

  public getStoplight(v: IValue): StopLight | null {
    for (const s of this._stopLights) {
      if (s.hasValue(v)) {
        return s;
      }
    }
    return null;
  }

  private _getStopPoint(v: IValue): StopPoint | null {
    for (const p of this._stopPoints) {
      if (p.hasValue(v)) {
        return p;
      }
    }
    return null;
  }

  private _getStopPointsRange(v: IValue): [StopPoint, StopPoint] {
    let i = -1;
    for (; i < this._stopPoints.length; i++) {
      if ((this._stopPoints[i] ? this._stopPoints[i].value <= v : true) &&
        (this._stopPoints[i + 1] ? v <= this._stopPoints[i + 1].value : true)) break;
    }
    return [this._stopPoints[i], this._stopPoints[i + 1]];
  }

  public forEach(fn: (s: IStoplight) => void): void {
    return this._stopLights.forEach(fn);
  }

  public forEachPoint(fn: (s: IStopPoint) => void): void {
    return this._stopPoints.forEach(fn);
  }

  public mapPoints<U>(callbackfn: (value: IStoplight, index: number, array: IStoplight[]) => U, thisArg?: any): U[] {
    return this._stopPoints.map(callbackfn);
  }

  public map<U>(callbackfn: (value: IStoplight, index: number, array: IStoplight[]) => U, thisArg?: any): U[] {
    return this._stopLights.map(callbackfn);
  }

  public getColor(e: IEntity, v: IValue): string {
    return this.getColorPair(e, v).color;
  }

  public getBgColor(e: IEntity, v: IValue): string {
    return this.getColorPair(e, v).bgColor;
  }

  public getColorPair(entity: IEntity, v?: IValue): IColorPair {
    if (typeof v === 'string') {
      return (v in this._constants) ? this._constants[v] : INVALID_COLOR_PAIR;
    }
    if (v == null) {
      return ('null' in this._constants) ? this._constants['null'] : INVALID_COLOR_PAIR;
    }
    if (typeof v !== 'number') {
      return INVALID_COLOR_PAIR;
    }

    // exact point
    const point: StopPoint = this._getStopPoint(v);
    if (point) {
      return point;
    }

    // exact light
    const light: StopLight = this.getStoplight(v);
    if (light) {
      return light;
    }

    // between points
    const [s, e] = this._getStopPointsRange(v);
    if (s && e) {
      const v1 = s.value, v2 = e.value;
      const k = (v1 >= v2) ? 0.5 : clamp(((v as number) - v1) / (v2 - v1), 0, 1);
      return {
        color: coloring.HSVColor.interpolateHSV(coloring.make(s.color).toHSV(), coloring.make(e.color).toHSV(), k).toString(),
        bgColor: coloring.HSVColor.interpolateHSV(coloring.make(s.bgColor).toHSV(), coloring.make(e.bgColor).toHSV(), k).toString(),
        // color: coloring.RGBColor.interpolateRGB(coloring.make(s.color).toRGB(), coloring.make(e.color).toRGB(), k).toString(),
        // bgColor: coloring.RGBColor.interpolateRGB(coloring.make(s.bgColor).toRGB(), coloring.make(e.bgColor).toRGB(), k).toString(),
      };
    } else if (s || e) {
      return (s || e);
    }

    return INVALID_COLOR_PAIR;
  }
}
