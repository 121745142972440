/// <reference path="../../defs/wellknown.d.ts" />
/// <reference path="../../defs/bi.d.ts" />

import clone from 'lodash/clone';
import isString from 'lodash/isString';
import isObject from 'lodash/isObject';
import property from 'lodash/property';
import { parse as wktParse } from 'wellknown';
import { OptionsProvider } from '../../config/OptionsProvider';
import { Observable, IUrl, AppConfig } from '@luxms/bi-core';
import { Stoplights } from '../../config/Stoplights';
import { fixColorPairEx } from '../../config/ColorPair';
import { isInvalidColorPair } from '../../config/color-resolvers';
import { $eid, $eidx, $esid } from '../../libs/imdas/list';
import { Unit, Metric, Location, Period, Preset, PeriodType } from './entities';
import { IS_M, IS_L, IS_P, lang, makeColor, parseVizelTypeString, fixViewClass } from '../../utils/utils';
import { IConfigHelper, IDashboard, IDashlet, IDashletsHelper, IDatasetModel, IVizelConfig } from './types';
import { lpeRun } from '../../utils/lpeRun';
import {
  IAxis,
  IColorPair,
  IColorResolver, IEntity, ILocationArea, ILocationCard, ILocationCardField, ILocationsHelper, IMetric,
  IOptionsProvider, IPeriod, IPeriodsHelper, IPreset, IRange, ISpatial,
  IStoplight,
  IStoplights,
  ISubspacePtr, ITag,
  ITitleResolver, ITreeNode, IValue, IVizelConfigDisplay,
  tables,
} from '../../defs/bi';
import VizelController from './VizelController';

type IDataSourceStyle = tables.IDataSourceStyle;

function isEntityIdInArray(entityId: number | string, arr: (number | string)[]): boolean {
  for (let id of arr) {
    if (entityId == id) {             // == : might compare string with number
      return true;
    }
  }
  return false;
}


// Для тегов = они могут храниться как объект {}
function convertToArray(objectOfHashs: any = []): string[] {
  if (Array.isArray(objectOfHashs)) return objectOfHashs;
  return Object.keys(objectOfHashs).map((key) => key + ':' + objectOfHashs[key]);
}


// TODO: add limit, sort
export class VizelConfigDisplay implements IVizelConfigDisplay {
  private readonly _dataset: IDatasetModel;
  private readonly _raw: tables.IVizelConfigDisplay = {};
  private readonly _stoplights: Stoplights | null;
  public readonly group: any;

  public constructor(dataset: IDatasetModel, raw: tables.IVizelConfigDisplay) {
    this._dataset = dataset;
    this._raw = raw;
    this._stoplights = raw.stoplight ? Stoplights.create(raw.stoplight) : null;
    this.group = raw.group;

    for (let key in this._raw) {
      if (this._raw.hasOwnProperty(key)) {      // naive copy: TODO: refactor
        (this as any)[key] = this._raw[key];
      }
    }
  }

  public clone(): VizelConfigDisplay {
    const result: VizelConfigDisplay = new VizelConfigDisplay(this._dataset, this._raw);
    return result;
  }

  public hasRange(): boolean {
    let r: IRange = this.getRange();
    return r[0] != null || r[1] != null;
  }

  public getRange(): IRange | null {
    return (this._raw.range != null) ? this._raw.range : [null, null];
  }

  public getSort(): string | null {
    return (this._raw.sort != null) ? String(this._raw.sort).toLowerCase() : null;
  }

  public getLimit(): number | null {
    return (this._raw.limit != null) ? this._raw.limit : null;
  }

  public getSortBy(): string {
    return (this._raw.sortBy != null) ? this._raw.sortBy : null;
  }

  public getVAxisWidth(): number {
    return (('vAxis' in this._raw) && this._raw.vAxis.width) || null;
  }

  public setSort(v: string): void {
    if (v) {
      this._raw.sort = v;
    } else {
      delete this._raw.sort;
    }
  }

  public disableRange(): void {
    delete this._raw.range;
  }

  public getGradient(): string | null {
    return (this._raw.gradient != null) ? this._raw.gradient : null;
  }

  public getStackGroupIndex(e: IEntity): number {
    if (Array.isArray(this._raw.stackGroups)) {
      for (let i = 0; i < this._raw.stackGroups.length; i++) {
        const grp: (string | number)[] = this._raw.stackGroups[i];
        if (Array.isArray(grp) && isEntityIdInArray(e.id, grp)) {
          return i;            // return index of group with such entity
        }
      }
    }
    return null;
  }

  public getStoplights(): IStoplights | null {
    return this._stoplights;
  }

  public getStoplight(v: IValue): IStoplight {
    return this._stoplights ? this._stoplights.getStoplight(v) : null;
  }
}


export class VizelConfig implements IVizelConfig {
  public readonly dataset: IDatasetModel;
  private readonly _options: IOptionsProvider;
  private _subspacePtr: SubspacePtr;
  public dataSource: tables.IDataSource;
  public view_class: string;
  public display: tables.IVizelConfigDisplay;

  public controller: any = null;

  private _display: VizelConfigDisplay;
  private _raw: tables.IRawVizelConfig;

  public title: string;
  public description: string;
  public legend: { [id: string]: tables.ILegendItem; } = null;
  public badValueColor: string;
  public goodValueColor: string;
  public onClickDataPoint: string | any;
  public onClick: string | any;
  public cardId: string;
  public externalUrl: IUrl;
  public dashboardId: string;
  public dashId: string;
  public normStrategy: string;
  public context: any;

  public titleContext: string[];
  public colorResolver: IColorResolver = null;
  public titleResolver: ITitleResolver = null;

  // deprecated
  public chartStyle: string;
  public showLegend: boolean = null;

  public constructor(ds: IDatasetModel, raw: tables.IRawVizelConfig = null, view_class?: string) {
    this.dataset = ds;
    this._raw = clone(raw) || {};

    const chartStyle: string = this._raw.chartStyle;
    // Также сохраняем в конфиг полученное значение
    this._raw.view_class = this.view_class = fixViewClass(this._raw.vizelType || this._raw.view_class || view_class, chartStyle);

    this.display = raw.display;

    this._options = new OptionsProvider(Array.isArray(this._raw.options) ? this._raw.options : null);

    // Add options from dataset
    if (this.dataset.getConfigHelper().getBoolValue('vizels.*.options.DisplayAllBadges')) {           // TODO: create common method
      this._options.addOption('DisplayAllBadges');
    }
    // hack
    this['normStrategy'] = this.dataset.getConfigHelper().getStringValue('norm.displayStyle', 'line');

    this.dataSource = this._raw.dataSource || {};

    this._display = new VizelConfigDisplay(this.dataset, this._raw.display || (this._raw.display = {}));

    this._subspacePtr = new SubspacePtr(this.dataSource, this._options, view_class === 'BIChartDashView' && chartStyle === 'Pie');

    this.controller = new VizelController(this, 'ShowDrillDownMenu');


    this.legend = this._raw.legend;
    this.badValueColor = this._raw.badValueColor;
    this.goodValueColor = this._raw.goodValueColor;
    this.title = raw.title;
    this.description = raw.description;
    this.cardId = raw.cardId;
    this.externalUrl = raw.externalUrl;
    this.titleContext = raw.titleContext;
    this.onClick = raw.onClick || null;
    this.onClickDataPoint = raw.onClickDataPoint || null;
    this.chartStyle = raw.chartStyle || null;
    this.context = raw.context || null;
  }

  public getDataset(): IDatasetModel {
    return this.dataset;
  }

  /**
   * @method
   * @description Возвращает тип визеля (значения ключа view_class)
   */
  public getVizelType(): string {
    return this.view_class;
  }

  /**
   * @method
   * @description Устанавливает тип визеля (значения ключа view_class)
   */
  public setVizelType(vizelType: string): void {
    this.view_class = this._raw.view_class = vizelType;
  }

  /**
   * @method
   * @description Возвращает объект subspacePtr
   */
  public getSubspacePtr(): ISubspacePtr {
    return this._subspacePtr;
  }

  /**
   * @method
   * @description Отдает склонированый конфиг визеля
   */
  public getRaw(): tables.IRawVizelConfig {
    const o: tables.IRawVizelConfig = clone(this._raw);
    const vts = parseVizelTypeString(this.getVizelType());
    o.view_class = vts.type + (vts.inner ? '/' + vts.inner : '');

    if (o.view_class === 'compare-sort') {                                // interporability
      o.view_class = 'classified-bar';
    }

    delete o['widgetType'];
    delete o['controller'];
    return o;
  }

  /**
   * @return {IStoplights}
   * @description Возвращает значения из конфига визеля display:{ stoplights:any };
   */
  public getStoplights(): IStoplights | null {
    return this.getDisplay().getStoplights();
  }

  /**
   * @param v
   * @param vizelType
   * @deprecated
   * @description Меняет напрямую объект options, не сигнализирует об обновлении конфига!!
   */
  public getStoplight(v: number, vizelType?: string): IStoplight {
    return this.getDisplay().getStoplight(v, vizelType);
  }

  /**
   * @param {string} optionId - имя опции
   * @param {any} defaultValue - Зн-ие если опции не найдена.
   * @return {boolean | undefined}
   * @description Метод ищет эту опцию в конфиги визеля, массив options, если не нашёл ищет в конфиги Датасета
   */
  public getOption(optionId: string, defaultValue?: any): boolean | null {
    // get from vizel config
    let result: boolean | null = this._options.getOption(optionId, defaultValue);
    if (result != null) {
      return result;
    }

    // get from dataset config
    const vizelType = parseVizelTypeString(this.getVizelType()).type;
    result = this._getOptionFromDatasetConfig(optionId, vizelType);
    if (result != null) {
      return result;
    }

    result = this._getOptionFromDatasetConfig(optionId, '*');
    if (result != null) {
      return result;
    }

    return defaultValue;
  }

  public getOptionCount(optionId: string): number {
    let result: number = this._options.getOptionCount(optionId);
    return result;
    // TODO: use ds config and global config
  }

  private _getOptionFromDatasetConfig(optionId: string, vizelType: string): boolean | undefined {
    const ch = this.dataset.getConfigHelper();

    // maybe in dataset as specific keys
    let result: boolean | null = ch.getBoolValue(`vizels.${vizelType}.options.${optionId}`, null);
    if (result != null) {
      return true;
    }

    let dsOptions: any = ch.getValue(`vizels.${vizelType}.options`);
    if (dsOptions == null) {
      return undefined;
    }

    if (typeof dsOptions === 'string') dsOptions = dsOptions.split(',');
    const optionsProvider = new OptionsProvider(dsOptions);

    result = optionsProvider.getOption(optionId);
    return result;
  }

  /**
   * @param optionId
   * @param value
   * @deprecated
   * @description Меняет напрямую объект options, не сигнализирует об обновлении конфига!!
   */
  public setOption(optionId: string, value: boolean): void {   // deprecated
    return this._options.setOption(optionId, value);
  }

  /**
   * @param {string} optionId - имя опции
   * @deprecated Метод ищет опцию в конфиги визеля, в массиве options
   */
  public hasOption(optionId: string): boolean {
    return this.getOption(optionId) === true;
  }

  /**
   * @param optionId
   * @deprecated
   * @description Меняет напрямую объект options, не сигнализирует об обновлении конфига!!
   */
  public addOption(optionId: string): boolean {
    return this._options.addOption(optionId);
  }

  /**
   * @param optionId
   * @deprecated
   * @description Меняет напрямую объект options, не сигнализирует об обновлении конфига!!
   */
  public removeOption(optionId: string): boolean {
    return this._options.removeOption(optionId);
  }

  /**
   * @method
   * @return {string | null}
   * @description возвращает значение ключа url, в конфиги визеля
   */
  public getUrl(): string | null {
    return this._raw.url || null;                // empty: undefined, null, ''
  }

  /**
   * @method
   * @return {string | null}
   * @description возвращает значение ключа bgImage, в конфиги визеля
   */
  public getBgImage(): string | null {
    return this._raw.bgImage || null;                // empty: undefined, null, ''
  }

  /**
   * @param key
   * @description not implemented
   */
  public getProperty(key): any {
    // not implemented
    return null;
  }

  /**
   * @param key
   * @param value
   * @description not implemented
   */
  public setProperty(key: string, value: any): void {
    // not implemented
  }

  /**
   * @param {IEntity} e - сущность оси
   * @param idx
   * @description Ищет в dataSource.style объект, * | axisId | axisId[id], в МЛП ( metrics[mId], locations[lId], periods[pId])
   */
  public getLegendItem(e: IEntity, idx: number = -1): tables.ILegendItem {
    if (!e) return null;

    let style: IDataSourceStyle;
    if (typeof this._raw.style === 'object') style = this._raw.style;                               // first check config.style
    else if (this.dataSource && (typeof this.dataSource.style === 'object')) style = this.dataSource.style;   // then dataSource.style
    else style = {};

    let styleForId: any = null;
    const axisId: string = e.axisId || '';

    const axisIds = e.axisIds || [];     // combine subspace
    const ids = e.ids || [];             // combine subspace


    if (style.metrics && IS_M(e) && (e.id in style.metrics)) styleForId = style.metrics[e.id];
    else if (style.locations && IS_L(e) && (e.id in style.locations)) styleForId = style.locations[e.id];
    else if (style.periods && IS_P(e) && (e.id in style.periods)) styleForId = style.periods[e.id];
    else if (axisId === e.id && (typeof style[axisId] === 'object') && style[axisId] !== null) styleForId = style[axisId];
    else if ((typeof style[axisId] === 'object') && style[axisId] !== null && (e.id in style[axisId])) styleForId = style[axisId][e.id];
    if ((typeof style[axisId] === 'object') && style[axisId] !== null && style[axisId]?.options && (e.id in style[axisId].options)) styleForId = style[axisId].options[e.id];

    // беру по последнему, если набор
    if (axisIds.length > 0 && ids.length > 0 && axisIds.length === ids.length) {
      if (!styleForId) styleForId = {};
      const allTitles = [];
      for (let i = 0; i < axisIds.length; i++) {
        const axisIdd = axisIds[i];
        const id = ids[i];
        const title = style[axisIdd]?.[id]?.title ?? style[axisIdd]?.['*']?.title ?? e.titles[i];
        allTitles.push(title);
        styleForId = {...style?.['*'], ...styleForId, ...style?.[axisIdd]?.['*'], ...style?.[axisIdd]?.[id]};
      }
      styleForId.title = allTitles.join(' ');
    }

    let legendForId: any = null;
    // Deprecated: legend
    if ((typeof this.legend === 'object') && this.legend && (e.id in this.legend)) legendForId = this.legend[e.id];
    else if (Array.isArray(this.legend) && (idx in this.legend)) legendForId = this.legend[idx];

    // try '*'
    let styleForAll: any = null;
    if ((typeof style[axisId] === 'object') && ('*' in style[axisId])) styleForAll = style[axisId]['*'];


    let legendForAll: any = null;
    if ((typeof this.legend === 'object') && this.legend && ('*' in this.legend)) legendForAll = this.legend['*'];


    // to speedup
    if (!styleForId && !legendForId && !styleForAll && !legendForAll) return ({} as any);
    if (styleForId && !legendForId && !styleForAll && !legendForAll) return styleForId;
    if (!styleForId && legendForId && !styleForAll && !legendForAll) return legendForId;

    let options = (styleForId?.options || []).concat(legendForId?.options || [], styleForAll?.options || [], legendForAll?.options || []);

    let result = {
      ...legendForAll,
      ...styleForAll,
      ...legendForId,
      ...styleForId,
      options,
    };

    return result;
  }

  private _getLegendColorPair(e: IEntity, idx?: number): IColorPair {
    const li: tables.ILegendItem = this.getLegendItem(e, idx);
    return fixColorPairEx({
      color: li ? makeColor(li.color) : null,
      bgColor: li ? makeColor(li.bgColor) : null,
    });
  }

  private _getLegendColor(e: IEntity, idx?: number): string {
    return this._getLegendColorPair(e, idx).color;
  }

  private _getLegendBgColor(e: IEntity, idx: number): string {
    return this._getLegendColorPair(e, idx).bgColor;
  }

  private _getLegendTitle(e: IEntity): string {
    const li: tables.ILegendItem = this.getLegendItem(e);
    let title: string | null = li && ('title' in li) && (isString(li.title)) ? li.title : null;
    if (title && title.startsWith('lpe:')) {
      try {
        title = lpeRun(title, {e, parent: e => e.parent});
      } catch (err) {
        console.error(err);
        title = null;
      }
    }
    return title;
  }

  /**
   * @param {IEntity} e - сущность оси
   * @param {number} v
   * @param idx
   * @description Наследует цвет из colorResolver | ищет цвет в style | выбирает цвет из skin.colorPallete
   */
  public getColor(e: IEntity, v: number, idx?: number): string {
    const colorPalette = this._raw.colorPalette;
    const color: string = this.colorResolver ? this.colorResolver.getColor(e, v, idx, colorPalette) : null;
    return color || this._getLegendColor(e, idx);
  }

  /**
   * @param {IEntity} e - сущность оси
   * @param {number} v
   * @param idx
   * @description Наследует фон из colorResolver | ищет цвет в style | выбирает цвет из skin.colorPallete
   */
  public getBgColor(e: IEntity, v: number, idx?: number): string {
    const bgColor: string = this.colorResolver ? this.colorResolver.getBgColor(e, v, idx) : null;
    return bgColor || this._getLegendBgColor(e, idx);
  }

  public getColorPair(e: IEntity, v?: IValue, idx?: number): IColorPair {
    let cp: IColorPair = null;
    if (this.colorResolver && this.colorResolver.getColorPair) {
      cp = this.colorResolver.getColorPair(e, v, idx);
    }
    if (isInvalidColorPair(cp) && this.colorResolver) {
      cp = {
        color: this.colorResolver.getColor(e, v, idx),
        bgColor: this.colorResolver.getBgColor(e, v, idx),
      };
    }
    if (isInvalidColorPair(cp)) {
      cp = this._getLegendColorPair(e, idx);
    }
    return fixColorPairEx(cp);
  }

  /**
   * @param {IEntity} e - сущность
   * @description Ищет title в titleResolver | ищет ключ title в style
   */
  public getTitle(e: IEntity): string {
    let title: string = this.titleResolver ? this.titleResolver.getTitle(e) : null;
    if (title != null) return title;
    title = this._getLegendTitle(e);
    if (title != null) return title;
    return e?.title;
  }

  /**
   * @param {IEntity} e - сущность
   * @description Ищет ключ format в style
   */
  public getFormat(e: IEntity): string {
    const style = this.getLegendItem(e);
    return style?.format || '';
  }

  public setTitle(title: string): void {
    this._raw.title = title;
    this.title = title;
  }

  public getDisplay(): IVizelConfigDisplay {
    return this._display;
  }

  public getRange(): IRange {
    return this._display.getRange();
  }

  public disableRange(): void {
    this._display.disableRange();
  }

  public serialize(): tables.IRawVizelConfig {
    throw new Error('Not implemented');
  }

  public clone(): IVizelConfig {
    const c: VizelConfig = new VizelConfig(this.dataset, this._raw);
    c.setVizelType(this.getVizelType());
    c.dataSource = this.dataSource;
    c.dashId = this.dashId;
    c.dashboardId = this.dashboardId;
    c.legend = this.legend;
    c.badValueColor = this._raw.badValueColor;
    c.goodValueColor = this._raw.goodValueColor;
    c.title = this.title;
    c.description = this.description;
    c.cardId = this.cardId;
    c.externalUrl = this.externalUrl;
    c.titleContext = this.titleContext;
    c.controller = this.controller;
    c.colorResolver = this.colorResolver;
    c.titleResolver = this.titleResolver;
    return c;
  }
}


export class TreeNode<T> implements ITreeNode<T> {
  public axisId: string = '';
  public id: number | string;
  public parent: T = null;
  public root: T = null;
  public children: T[] = [];

  public constructor(parent: T = null) {
    this.parent = parent;
    this.root = this.parent ? (this.parent as any).root : null;
  }

  public addChild(child: T): T {
    // TODO: check id
    this.children = [...this.children, child];
    (child as any).parent = this;
    (child as any).root = this.root;
    (child as any).axisId = this.axisId;
    return (this as any);
  }

  public getChildren(): T[] {
    return this.children;
  }

  public getDescendants(): T[] {
    return Array.prototype.concat.apply(
        [],
        this.children.map(
            (c: any) => Array.prototype.concat.apply([c], c.getDescendants())));
  }

  public getParent(): T {
    return this.parent;
  }
}


export class Tag extends TreeNode<ITag> implements ITag {
  public axisId: string;
  public id: number | string;
  public title: string;

  public children: Tag[] = [];
  public parent: Tag;
  public root: Tag;

  public constructor(id: number | string, title: string = null, parent: Tag = null) {
    super(parent);
    this.id = id;
    this.title = title != null ? title : String(id);
    this.axisId = this.parent ? String(this.parent.id) : null;
  }

  public addChild(child: ITag): ITag {
    super.addChild(child);
    // TODO: might be very slow
    const root = this.root as TagGroup;
    root.entities = [...root.entities, child];
    return this;
  }

  public appendTo(parent: ITag): ITag {
    parent.addChild(this);
    return this;
  }

  // deprecated: use $eid(tag.children, id)
  public getChildById(id: string): ITag {
    return $eid(this.children, id) || null;
  }
}


// keep right order of tags
export class TagGroup extends Tag implements IAxis<ITag> {
  public children: Tag[] = [];
  public axisId: string;
  public entities: ITag[] = [];

  public constructor(id: string, title?: string) {
    super(id, title);
    this.root = this;
    this.axisId = String(this.id);
  }

  public getTag(idx: number): ITag {
    return this.children[idx];
  }

  public addTags(tags: ITag[]): ITag {
    tags.forEach((t: ITag) => this.addChild(t));
    return this;
  }
}


// predefined tags:
let MONTH_OF_YEAR_TAG_GROUP: TagGroup;
let DAY_OF_WEEK_TAG_GROUP: TagGroup;
let SECOND_OF_MINUTE_TAG_GROUP: TagGroup;
let MINUTE_OF_HOUR_TAG_GROUP: TagGroup;
let HOUR_OF_DAY_TAG_GROUP: TagGroup;
let DAY_OF_MONTH_TAG_GROUP: TagGroup;
let DAY_OF_YEAR_TAG_GROUP: TagGroup;
let QUARTER_OF_YEAR_TAG_GROUP: TagGroup;


let tagsInitialized = false;

// нужна функция инициализации, потому что используется lang() который может быть не инициализирован
function initTags() {
  if (tagsInitialized) return;
  tagsInitialized = true;

  MONTH_OF_YEAR_TAG_GROUP = new TagGroup('monthOfYear');
  MONTH_OF_YEAR_TAG_GROUP.addTags([
    new Tag('january', lang('months_titles')[0]),
    new Tag('february', lang('months_titles')[1]),
    new Tag('march', lang('months_titles')[2]),
    new Tag('april', lang('months_titles')[3]),
    new Tag('may', lang('months_titles')[4]),
    new Tag('june', lang('months_titles')[5]),
    new Tag('july', lang('months_titles')[6]),
    new Tag('august', lang('months_titles')[7]),
    new Tag('september', lang('months_titles')[8]),
    new Tag('october', lang('months_titles')[9]),
    new Tag('november', lang('months_titles')[10]),
    new Tag('december', lang('months_titles')[11]),
  ]);

  DAY_OF_WEEK_TAG_GROUP = new TagGroup('dayOfWeek');
  DAY_OF_WEEK_TAG_GROUP.addTags([
    new Tag('monday', lang('days_titles')[0]),
    new Tag('tuesday', lang('days_titles')[1]),
    new Tag('wednesday', lang('days_titles')[2]),
    new Tag('thursday', lang('days_titles')[3]),
    new Tag('friday', lang('days_titles')[4]),
    new Tag('saturday', lang('days_titles')[5]),
    new Tag('sunday', lang('days_titles')[6]),
  ]);

  SECOND_OF_MINUTE_TAG_GROUP = new TagGroup('secondOfMinute');
  SECOND_OF_MINUTE_TAG_GROUP.addTags(Array.apply(null, Array(60)).map((_, i) => new Tag(`second${i}`, `${i}`)));

  MINUTE_OF_HOUR_TAG_GROUP = new TagGroup('minuteOfHour');
  MINUTE_OF_HOUR_TAG_GROUP.addTags(Array.apply(null, Array(60)).map((_, i) => new Tag(`minute${i}`, `${i}`)));

  HOUR_OF_DAY_TAG_GROUP = new TagGroup('hourOfDay');
  HOUR_OF_DAY_TAG_GROUP.addTags(Array.apply(null, Array(24)).map((_, i) => new Tag(`hour${i}`, `${i}`)));

  DAY_OF_MONTH_TAG_GROUP = new TagGroup('dayOfMonth');
  DAY_OF_MONTH_TAG_GROUP.addTags(Array.apply(null, Array(31)).map((_, i) => new Tag(`date${i + 1}`, `${i + 1}`)));

  DAY_OF_YEAR_TAG_GROUP = new TagGroup('dayOfYear');
  DAY_OF_YEAR_TAG_GROUP.addTags(Array.apply(null, Array(366)).map((_, i) => new Tag(`day${i + 1}`, `${i + 1}`)));

  QUARTER_OF_YEAR_TAG_GROUP = new TagGroup('quarterOfYear');
  QUARTER_OF_YEAR_TAG_GROUP.addTags(Array.apply(null, Array(4)).map((_, i) => new Tag(`q${i + 1}`, `${i + 1}`)));
}


//
//  Config Helper
//
export class ConfigHelper implements IConfigHelper {
  private config: { [key: string]: string } = {};

  public constructor(rawConfigs: tables.IConfigItem[], rawDsConfig: any) {
    this.update(rawConfigs, rawDsConfig);
  }

  public update(rawConfigs: tables.IConfigItem[], rawDsConfig: any): void {
    if (rawConfigs) {
      this.addData(rawConfigs);
    } else {
      this.addHashData(rawDsConfig);
    }
  }

  // incremental
  private _addData(item: tables.IConfigItem): void {
    this.config[item.cfg_key] = item.cfg_val;
  }

  public addData(configItems: tables.IConfigItem[]): void {
    for (let configItem of configItems) {
      this._addData(configItem);
    }
  }

  private _addHashData(items: any, prefix: string): void {
    for (let key in items) {
      if (items.hasOwnProperty(key) && isObject(items[key])) {
        this._addHashData(items[key], prefix + key + '.');
      } else {
        this._addData({cfg_key: prefix + key, cfg_val: String(items[key])});
      }
    }
  }

  public addHashData(items: any): void {
    if (isObject(items)) {
      this._addHashData(items, '');
    }
  }

  private _getValue(key: string): any {
    return (key in this.config) ? this.config[key] : undefined;
  }

  public hasValue(key: string): boolean {
    return key in this.config;
  }

  private _getGlobalConfigValue(key: string): any {
    const path: any = key.split('.');
    const value = property(path)(AppConfig.getModel().dataset);
    return value;
  }

  public getValue(key: string, defaultValue: any = null): any {
    const dsConfigValue: any = this._getValue(key);
    if (dsConfigValue !== undefined) {
      return String(dsConfigValue);
    }
    const globalConfigValue: any = this._getGlobalConfigValue(key);
    if (globalConfigValue !== undefined) {
      if (globalConfigValue === true) return 'YES';
      if (globalConfigValue === false) return 'NO';
      return String(globalConfigValue);
    }
    return defaultValue;
  }

  public getStringValue(key: string, defaultValue: string = null): string {
    return this.getValue(key, defaultValue);
  }

  public getIntValue(key: string, defaultValue: number = null): number {
    const sValue: string = this.getStringValue(key);
    return sValue !== null ? parseInt(sValue) : defaultValue;
  }

  public getFloatValue(key: string, defaultValue: number = null): number {
    const sValue: string = this.getStringValue(key);
    return sValue !== null ? parseFloat(sValue) : defaultValue;
  }

  public getBoolValue(key: string, defaultValue: boolean | null = false): boolean | null {
    const value: any = this.getValue(key, null);
    if (value === true || value === 'YES' || value === 'TRUE' || value === '1') return true;
    if (value === false || value === 'NO' || value === 'FALSE' || value === '0') return false;
    return defaultValue;
  }

  public getEnumValue(key: string, values: string[], defaultValue: string = null): string {
    const sValue: string = this.getStringValue(key);
    if (values.indexOf(sValue) === -1) return defaultValue;
    return sValue;
  }

  public getStringArray(key: string, defaultValue: string[] = null): string[] {
    const sValue: string = this.getStringValue(key);
    return (sValue !== null) ? sValue.split(',') : defaultValue;
  }

  public getIntArray(key: string, defaultValue: number[] = null): number[] {
    const sArr: string[] = this.getStringArray(key);
    return (sArr !== null) ? sArr.map((_) => parseInt(_)) : defaultValue;
  }

  public getEnterUrl(schema_name: string): IUrl {
    let urlRoute: string;
    switch (this.getStringValue('startup.page', '').toLowerCase()) {
      case 'map':
        urlRoute = 'map';
        break;
      case 'charts':
      case 'trends':
      case 'plots':
        urlRoute = 'trends';
        break;
      case 'dashboard':
      case 'dashboards':
        urlRoute = 'dashboards';
        break;
      default:
        urlRoute = 'dashboards';                                                                    // TODO: check dashboards exists or use trends
    }
    const url: IUrl = {
      path: ['ds', schema_name, urlRoute],
      segment: 'ds',
      segmentId: schema_name,
      route: urlRoute,
      preset: this.getIntValue('startup.preset.id'),
      locations: this.hasValue('startup.locations') ? this.getStringValue('startup.locations').split(',') : null,
      metrics: this.hasValue('startup.metrics') ? this.getStringValue('startup.metrics').split(',') : null,
      mf: {
        e: this.hasValue('startup.map.fill.metric') && this.hasValue('startup.map.fill.locations'),
        m: this.getStringValue('startup.map.fill.metric'),
        ls: this.hasValue('startup.map.fill.locations') ? this.getStringArray('startup.map.fill.locations') : null,
      },
      period: {
        start: this.getStringValue('startup.period.start'),
        end: this.getStringValue('startup.period.id'),
      },
      dboard: this.getStringValue('startup.dashboard.id'),
      // TODO: implement ao="LPM" | "MLP"
      // loc: this.getStringValue('startup.trends.yAxis') === 'locations',
      geo: {
        lat: this.getFloatValue('startup.map.geo.latitude'),
        lng: this.getFloatValue('startup.map.geo.longitude'),
        zoom: this.getIntValue('startup.map.geo.zoom'),
      },
      dash: null,
    };

    return url;

    // const sUrl: string = urlState.buildUrl(
    // return sUrl;
  }
}


//
//  PeriodsHelper class
//
export class PeriodsHelper extends Observable implements IPeriodsHelper {
  private _defaultPeriodType: number = null;
  public periods: Period[] = [];
  private periodsByPt: { [periodType: number]: Period[] } = {};
  private _periodTypes: number[] = [];                            // ids of used period types
  private _tagGroups: { [tagGroupId: string]: TagGroup } = {};

  public constructor(raws: tables.IPeriodsItem[], startupPeriodType: number) {
    super();

    initTags();

    // populate local tagGroups
    [SECOND_OF_MINUTE_TAG_GROUP, MINUTE_OF_HOUR_TAG_GROUP, HOUR_OF_DAY_TAG_GROUP,
      DAY_OF_MONTH_TAG_GROUP, DAY_OF_WEEK_TAG_GROUP, MONTH_OF_YEAR_TAG_GROUP,
      QUARTER_OF_YEAR_TAG_GROUP, DAY_OF_YEAR_TAG_GROUP, new TagGroup('year'),
    ].forEach((tg: TagGroup) => this._tagGroups[tg.title] = tg);

    this.periods = raws.map((raw: tables.IPeriodsItem) => this._createEntity(raw));
    this._rebuildPeriodsByPt();
    this._rebuildTree();
    this.periods.forEach(p => this._setupTagsOnPeriod(p));

    // this.periods.forEach((m, idx) => m.srt = idx);
    // this._periodsByPt = this._rebuildTree();

    this.setDefaults(startupPeriodType);
  }

  public update(rawPeriods: tables.IPeriodsItem[], startupPeriodType: number): void {
    this.addPeriods(rawPeriods);
    this.setDefaults(startupPeriodType);
  }

  private _createEntity(raw: tables.IPeriodsItem): Period {
    const period: Period = new Period(raw);

    let rawTags = [].concat(convertToArray(raw.tags), convertToArray(raw.config?.tags));

    rawTags.forEach((tagDescription: string) => {
      const path: string[] = tagDescription.split(':');
      const tagGroupName: string = path.splice(0, 1)[0];
      if (!(tagGroupName in this._tagGroups)) this._tagGroups[tagGroupName] = new TagGroup(tagGroupName);

      let t: ITag = this._tagGroups[tagGroupName];
      for (const p of path) {
        const next: ITag = t.getChildById(p) || (new Tag(p)).appendTo(t);
        t = next;
      }

      period.addTag(t);
    });

    return period;
  }

  private _rebuildPeriodsByPt() {
    this.periodsByPt = {};
    for (let i = 0; i < this.periods.length; i++) {
      const p = this.periods[i];
      const pt: number = p.period_type;
      if (pt in this.periodsByPt) {
        this.periodsByPt[pt].push(p);
      } else {
        this.periodsByPt[pt] = [p];
      }
    }

    this._periodTypes = Object.keys(this.periodsByPt).map(pt => +pt);
    this._periodTypes.sort((a, b) => b - a);       // sort order: decrease
  }

  private _rebuildTree(): void {
    if (!this.periods.length) return;

    this.periods.forEach(m => m.children = []);         // children array is populated in setParent method

    const PERIOD_TYPE_YEAR: number = 8;
    let prevByPt = [null, null, null, null, null, null, null, null, null];

    this.periods[0].parent = null;
    prevByPt[this.periods[0].period_type] = this.periods[0];

    for (let i = 1; i < this.periods.length; i++) {
      const p: Period = this.periods[i];
      let parent: Period = null;

      for (let ppt = p.period_type + 1; ppt <= PERIOD_TYPE_YEAR; ppt++) {
        if (prevByPt[ppt]) {
          parent = prevByPt[ppt];
          break;
        }
      }

      p.parent = parent;
      if (parent) parent.children.push(p);

      prevByPt[p.period_type] = p;
    }

    /*
    var printP = (p, spaces) => { console.log(spaces, p.title + " (" + p.period_type + ") " + p.period_id); p.children.forEach(pp =>printP(pp, spaces + '    ') )  }
    roots = dataset.periods.filter(p => !p.parent)
    roots.forEach(p => printP(p, ''));
    */
  }

  private _setupTagsOnPeriod(p: Period): void {
    const pt: number = p.period_type;

    // TODO: _tagsByLevel should be periodByLevel
    switch (pt) {
      case PeriodType.Seconds :
        p.addTag(this._tagGroups['secondOfMinute'].getTag(p.middleDate.second()));
      case PeriodType.Minutes :
        p.addTag(this._tagGroups['minuteOfHour'].getTag(p.middleDate.minute()));
      case PeriodType.Hours   :
        p.addTag(this._tagGroups['hourOfDay'].getTag(p.middleDate.hour()));
      case PeriodType.Days    :
        p.addTag(this._tagGroups['dayOfMonth'].getTag(p.middleDate.date() - 1));
        p.addTag(this._tagGroups['dayOfWeek'].getTag((p.middleDate.day() + 6) % 7));
        p.addTag(this._tagGroups['dayOfYear'].getTag(p.middleDate.dayOfYear() - 1));
      case PeriodType.Weeks   :
      case PeriodType.Months  :
        p.addTag(this._tagGroups['monthOfYear'].getTag(p.middleDate.month()));
      case PeriodType.Quarters:
        p.addTag(this._tagGroups['quarterOfYear'].getTag(p.middleDate.quarter() - 1));
      case PeriodType.Years   : {
        let yearTag = this._tagGroups['year'].getChildById(String(p.middleDate.year()));
        if (!yearTag) {
          this._tagGroups['year'].addChild(yearTag = new Tag(String(p.middleDate.year())));
        }
        p.addTag(yearTag);
      }
    }
  }

  private _addPeriod(raw: tables.IPeriodsItem): boolean {
    const id: string = ('period_id' in raw) ? raw.period_id.toString() : raw.id.toString();
    const periodType: number = +raw.period_type;

    if ($eidx(this.periods, id) !== -1) {
      // return this._periodsById[id].update(p);
      return false;
    }

    const period: Period = this._createEntity(raw);

    this.periods = this.periods.slice(0);
    this.periods.push(period);

    // TODO: setup parent!

    this._setupTagsOnPeriod(period);

    const pt: number = period.period_type;
    if (pt in this.periodsByPt) {
      this.periodsByPt[pt] = this.periodsByPt[pt].slice(0);
      this.periodsByPt[pt].push(period);
    } else {
      this.periodsByPt[pt] = [];
    }

    if (this._periodTypes.indexOf(periodType) === -1) {
      const newPeriodTypes: number[] = this._periodTypes.slice();
      newPeriodTypes.push(periodType);
      newPeriodTypes.sort((a, b) => b - a);
      this._periodTypes = newPeriodTypes;           // must change the whole object when modify
    }

    return true;
  }

  private _addPeriods(ps: tables.IPeriodsItem[]): boolean {
    let result: boolean = false;
    for (const p of ps) {
      if (this._addPeriod(p)) {
        result = true;
      }
    }
    return result;
  }

  public addPeriods(ps: tables.IPeriodsItem[]): void {
    if (this._addPeriods(ps)) {
      this._notify('update', null);
    }
  }

  private setDefaults(defaultPtId: number = null): void {
    this._defaultPeriodType = defaultPtId;

    if (this._periodTypes.indexOf(this._defaultPeriodType) === -1) {            // not found in list of used period types
      this._defaultPeriodType = this._periodTypes[0] || null;                   // take the most general period type
    }
  }

  public getPeriodsByTypeId(periodTypeId: number): IPeriod[] {
    return this.periodsByPt[periodTypeId] || [];
  }

  public getPeriodsByDatesAndType(from, to, periodType: number): IPeriod[] {
    return this.getPeriodsByTypeId(periodType)                                  // TODO: binary search
        .filter((e) => from ? e.endDate.isAfter(from.utc()) : true)
        .filter((e) => e.startDate.isSame(to.utc()) || e.startDate.isBefore(to.utc()));
  }

  public getAvailablePeriodTypes(): number[] {
    return this._periodTypes;
  }

  public getDefaultPeriodType(): number {
    return this._defaultPeriodType;
  }

  public getTagGroup(tagGroupName: string): TagGroup {
    return this._tagGroups[tagGroupName] || null;
  }

  public isFirst(p: IPeriod): boolean {
    const pt: number = p.period_type;
    const periods = this.getPeriodsByTypeId(pt);
    return periods.length && (periods[0].id === p.id);
  }

  public isLast(p: IPeriod): boolean {
    const pt: number = p.period_type;
    const periods = this.getPeriodsByTypeId(pt);
    return periods.length && (periods[periods.length - 1].id === p.id);
  }
}


export class UnitsHelper {
  public entities: Unit[] = [];

  public constructor(raws: tables.IUnitsItem[]) {
    this.entities = raws.map((raw: tables.IUnitsItem) => this._createEntity(raw));
  }

  public update(raws: tables.IUnitsItem[]) {
    // TODO: smart update
    // const id: string = ('dim_id' in raw) ? raw.dim_id.toString() : raw.id.toString();
    this.entities = raws.map((raw: tables.IUnitsItem) => new Unit(raw));
  }

  private _createEntity(raw: tables.IUnitsItem): Unit {
    return new Unit(raw);
  }
}


export class LocationCardFieldsHelper {
  public entities: ILocationCardField[];

  public constructor(raws: tables.ILocationCardField[], metrics: IMetric[]) {
    this.entities = raws.map((raw: tables.ILocationCardField) => this._createEntity(raw, metrics));
  }

  public update(raws: tables.ILocationCardField[], metrics: IMetric[]) {
    // TODO: implement
  }

  private _createEntity(raw: tables.ILocationCardField, metrics: IMetric[]): ILocationCardField {
    let metric: IMetric = null;
    if (raw.metric_id) {
      metric = $eid(metrics, raw.metric_id);
    } else {
      console.warn(`Could not find metric for location_card_field(id=${raw.id})`);
    }
    return {
      ...raw,
      id: String(raw.id),
      cardId: String(raw.card_id),
      metric,
    };
  }
}


export class LocationCardsHelper {
  public entities: ILocationCard[];

  public constructor(raws: tables.ILocationCard[], locationCardFields: ILocationCardField[]) {
    this.entities = raws.map((raw: tables.ILocationCard) => this._createEntity(raw, locationCardFields));
  }

  public update(raws: tables.ILocationCard[], locationCardFields: ILocationCardField[]) {
    // TODO: implement
  }

  private _createEntity(raw: tables.ILocationCard, locationCardFields: ILocationCardField[]): ILocationCard {
    const id: string = String(raw.id);
    const fields: ILocationCardField[] = locationCardFields.filter((f: ILocationCardField) => f.cardId === id);
    fields.sort((e1: ILocationCardField, e2: ILocationCardField) => e1.srt - e2.srt);

    const lc: ILocationCard = {
      ...raw,
      id,
      fields,
      config: {},
    };
    return lc;
  }
}


export class LocationAreasHelper {
  public entities: ISpatial[];

  public constructor(raws: tables.ILocationArea[]) {
    this.entities = raws.map((raw: tables.ILocationArea) => this._createEntity(raw));
  }

  public update(raws: tables.ILocationArea[]) {
    // TODO: smart update
  }

  private _createEntity(raw: tables.ILocationArea): ILocationArea {
    const id: string = ('sid' in raw) ? raw.sid.toString() : raw.id.toString();
    const lid: string = String(raw.loc_id);
    const wkt: string = ('WKT' in raw) ? raw.WKT : raw.wkt;

    let geoJSON: any = null;
    try {
      geoJSON = wkt ? wktParse(wkt) : null;
    } catch (err) {
      console.error(err);
      console.warn(`Invalid wkt: "${wkt}" for spatial id=${id}`);
    }

    const s: ISpatial = {
      ...raw,
      id,
      lid,
      geoJSON,
    };
    return s;
  }
}


//
// MetricsHelper class
//   organize working with parameters
//
export class MetricsHelper {
  public metrics: Metric[];
  public rootMetrics: Metric[];
  private _tagGroups: { [tagGroupId: number]: TagGroup } = {};

  public constructor(raws: tables.IMetricsItem[], units: Unit[], ch: ConfigHelper) {
    this.metrics = raws.map((raw: tables.IMetricsItem) => this._createEntity(raw, units));
    this.metrics.forEach((m, idx) => m.srt = idx);
    this.rootMetrics = this._rebuildTree();
  }

  public update(raws: tables.IMetricsItem[], units: Unit[]): void {
    // TODO: implement
  }

  private _createEntity(raw: tables.IMetricsItem, units: Unit[]): Metric {
    const metric: Metric = new Metric(raw);

    const unitId: number = metric.unit_id;
    const unit: Unit = $eid(units, unitId);
    metric.unit = unit;

    let rawTags = [].concat(convertToArray(raw.tags) || [], convertToArray(raw.config?.tags) || []);
    rawTags.forEach((tagDescription: string) => {
      const path: string[] = tagDescription.split(':');
      const tagGroupName: string = path.splice(0, 1)[0];
      if (!(tagGroupName in this._tagGroups)) this._tagGroups[tagGroupName] = new TagGroup(tagGroupName);

      let t: ITag = this._tagGroups[tagGroupName];
      for (const p of path) {
        const next: ITag = t.getChildById(p) || (new Tag(p)).appendTo(t);
        t = next;
      }

      metric.addTag(t);
    });

    return metric;
  }

  protected _rebuildTree(): Metric[] {
    this.metrics.forEach(m => m.children = []);

    this.metrics.forEach(m => {
      let p: Metric = null;

      if (m.parentId != null) {
        p = $eid(this.metrics, m.parentId);
      }

      if (p === m) {
        p = null;
      } else if (!p && m.parentId) {
        console.warn(`Got metric with parent=${m.parentId}, but no metric with such id found`);
      }

      m.setParent(p);
    });

    // return this.locations.filter(l => l.parent === null);
    return this.metrics.filter(m => m.tree_level === 0);
  }

  public getTagGroup(tagGroupName: string): TagGroup {
    return this._tagGroups[tagGroupName] || null;
  }
}


export class LocationsHelper implements ILocationsHelper {
  public locations: Location[];
  public roots: Location[];
  public tagAxes: { [axisId: string]: TagGroup } = {};

  public constructor(raws: tables.ILocationsItem[], locationCards: ILocationCard[], locationAreas: ILocationArea[]) {
    this.locations = raws.map((l: tables.ILocationsItem) => this._createEntity(l, locationCards, locationAreas));
    this.roots = this._rebuildTree();
  }

  public update(raws: tables.ILocationsItem[], locationCards: ILocationCard[], locationAreas: ILocationArea[]) {
    // TODO: implement
  }

  private _createEntity(raw: tables.ILocationsItem, locationCards: ILocationCard[], allLocationAreas: ILocationArea[]): Location {
    const numId: number = ('loc_id' in raw) ? raw.loc_id : raw.id;
    const id: string = numId.toString();
    const tree_level: number = raw.tree_level;

    const location: Location = new Location(raw);

    const locationAreas: ILocationArea[] = allLocationAreas.filter(la => la.lid === id);
    if (locationAreas.length > 0) {
      location.spatials = locationAreas;
    }

    // explicit location card
    const checkLocationCardProperty = (prop: string, value: number): ILocationCard => {
      for (let lc of locationCards) {
        if (lc[prop] && (-1 != lc[prop].indexOf(value))) {
          return lc;
        }
      }
      return null;
    };

    location.card = checkLocationCardProperty('level', tree_level) ||
        checkLocationCardProperty('tree_level', tree_level) ||
        checkLocationCardProperty('parent_id', raw.parent_id) ||
        checkLocationCardProperty('loc_id', numId);

    // TODO: move to method extractTags and updateTags
    let rawTags = [].concat(convertToArray(raw.tags) || [], convertToArray(raw.config?.tags) || []);
    rawTags.forEach((tagDescription: string) => {
      const path: string[] = tagDescription.split(':');
      const tagGroupName: string = path.splice(0, 1)[0];
      if (!(tagGroupName in this.tagAxes)) this.tagAxes[tagGroupName] = new TagGroup(tagGroupName);

      let t: ITag = this.tagAxes[tagGroupName];
      for (const p of path) {
        const next: ITag = t.getChildById(p) || (new Tag(p)).appendTo(t);
        t = next;
      }

      location.addTag(t);
    });

    return location;
  }

  protected _rebuildTree(): Location[] {
    this.locations.forEach(l => l.children = []);

    this.locations.forEach(l => {
      const pid: string = l.parent_id;
      const p: Location = $eid(this.locations, pid);

      if (p) {
        l.setParent(p);
      } else if (l.parent_id) {
        console.warn(`Got location with parent=${l.parent_id}, but no location with such id found`);
      }
    });

    // return this.locations.filter(l => l.parent === null);
    return this.locations.filter(l => l.tree_level === 0);
  }

  public getTagGroup(tagGroupName: string): TagGroup {
    return this.tagAxes[tagGroupName] || null;
  }
}


export class PresetsHelper {
  public entities: IPreset[];

  public constructor(raws: tables.IPresetsItem[], metrics: IMetric[]) {
    this.entities = raws.map((raw: tables.IPresetsItem) => this._createEntity(raw, metrics));
  }

  public update(raws: tables.IPresetsItem[], metrics: IMetric[]) {
    // TODO: implement
  }

  private _createEntity(raw: tables.IPresetsItem, allMetrics: IMetric[]): IPreset {
    const p: IPreset = new Preset(raw);
    p.metrics = $esid(allMetrics, raw.metrics);
    return p;
  }
}


export class SubspacePtr implements ISubspacePtr {
  private readonly _datasetId: string;

  public readonly koob: string;
  public readonly lookupId: string | number;
  public readonly dataSource: tables.IDataSource;

  public readonly metricsDrilldown: number = 0;
  public readonly locationsDrilldown: number = 0;
  public readonly disableLoadData: number = 0;

  private readonly axesOrder: string[];
  private readonly combineAxes: { xAxis: string[], yAxis: string[], zAxis: string[], tags: string[][] };
  private readonly _isCombine: boolean;

  public constructor(dataSource: tables.IDataSource, options: IOptionsProvider = null, doSwapXY: boolean = false) {
    this.dataSource = dataSource;

    if (this.dataSource.koob) this.koob = this.dataSource.koob;
    if (this.dataSource.lookupId) this.lookupId = this.dataSource.lookupId;


    if (options) {
      this.metricsDrilldown = options.getOptionCount('DrillDownByMetrics');
      this.locationsDrilldown = options.getOptionCount('DrillDownByLocations');
      this.disableLoadData = options.getOptionCount('DisableLoadData');
    }

    let order: string[] = ['locations', 'metrics', 'periods'];       // default

    const xchg = (i: number, j: number) => [order[i], order[j]] = [order[j], order[i]];
    const oset = (i: number, axisName: string) => xchg(i, order.indexOf(axisName));

    if (order.indexOf(dataSource.xAxis) !== -1) oset(2, dataSource.xAxis);
    if (order.indexOf(dataSource.yAxis) !== -1) oset(1, dataSource.yAxis);
    if (order.indexOf(dataSource.zAxis) !== -1) oset(0, dataSource.zAxis);


    let isCombine: boolean = false;

    const xAxis = (dataSource.xAxis || '').split(';').filter(Boolean);
    const yAxis = (dataSource.yAxis || '').split(';').filter(Boolean);
    const zAxis = (dataSource.zAxis || '').split(';').filter(Boolean);
    const tags = [];

    [xAxis, yAxis, zAxis].forEach((axis) => {
      if (axis.length > 1) isCombine = true;
      axis.forEach((ax, k) => {
        if (SubspacePtr.isTaggedAxisId(ax)) {
          isCombine = true;
          const [axisName, tagName] = SubspacePtr.extractTaggedAxisId(ax);
          tags.push([axisName, tagName]);
          axis[k] = tagName;
        }
      });
    });

    this._isCombine = isCombine;
    this.combineAxes = {xAxis, yAxis, zAxis, tags};
    this.axesOrder = order;
    this._datasetId = dataSource.dataset || null;
  }

  private static __tagNameRE: RegExp = /^(metrics|locations|periods)\.(.+)$/;

  // TODO: consider make this method private (it is currently used in model)
  public static isTaggedAxisId(axisId: string): boolean {
    return !!(axisId && String(axisId).match(SubspacePtr.__tagNameRE));
  }

  public static extractTaggedAxisId(axisId: string): [string, string] {               // return axisId, tagName
    let res: RegExpMatchArray;
    if (axisId && (res = String(axisId).match(SubspacePtr.__tagNameRE))) {
      return [res[1], res[2]];
    }
    throw new Error(`extractTaggedAxisId: ${String(axisId)} is not tagged`);
  }

  public getMIds(): string[] {  // TODO: may be string
    return this.dataSource.metrics || null;
  }

  public getLIds(): string[] {  // TODO: may be string
    return this.dataSource.locations || null;
  }

  public getPIds(): string[] | { start?: string; end?: string; type?: number; qty?: number } {  // TODO: may be string
    return this.dataSource.periods || null;
  }

  public getPType(): string {
    return this.dataSource.periodType || null;
  }

  public getCombineAxes(): { xAxis: string[], yAxis: string[], zAxis: string[], tags: string[][] } {
    return this.combineAxes || null;
  }

  /**
   *
   * @param axisName  the name of axis ('metrics', 'locations', ... 'tagged-root')
   */

  public getAxisEntityIds(axisName: string): string | string[] {
    return (axisName in this.dataSource) ? this.dataSource[axisName] : null;
  }

  public isCombine(): boolean {
    return this._isCombine;
  }

  public getAxesOrder(): string[] {
    return this.axesOrder.slice(0);
  }
}


export class Dashlet implements IDashlet {
  public axisId: string = 'dashlets';
  public id: string;
  public parentId: string;

  public title: string;
  public description: string;
  public layout: string;                     // V|H|''

  public children: Dashlet[] = [];
  public legend: any = null;

  private _dataset: IDatasetModel;
  private _dashboard: Dashboard;
  private _frame: tables.IConfigFrame;
  private _raw: tables.IDashletsItem;

  public constructor(dataset: IDatasetModel, dashboard: Dashboard, raw: tables.IDashletsItem) {
    this._dataset = dataset;
    this._dashboard = dashboard;
    this.id = ('view_id' in raw) ? raw.view_id.toString() : raw.id.toString();
    this.parentId = (raw.parent_id != null) ? raw.parent_id.toString() : null;

    if (raw.parent_id === raw.view_id) {
      console.warn(`Dash {id:${this.id}, dashboard_id:${this._dashboard ? this._dashboard.id : '-'}}  has parent_id same as id: should be null`);
      this.parentId = null;
    }

    this.update(raw);
  }

  public update(raw: tables.IDashletsItem): void {
    this._raw = clone(raw);
    const config: tables.IDashConfig = raw.config || {};

    this.title = raw.title;   // this.title = raw.title || '';
    this.description = config.description ?? raw.description ?? null;
    this.layout = raw.layout;
    this.legend = config.legend;

    this._frame = (this._raw.config && this._raw.config.frame) ? this._raw.config.frame : null;
  }

  public getRawVizelConfig(): tables.IRawVizelConfig {
    // TODO: cache
    // TODO: null if BiContainerDashView
    // const cfg: tables.IRawVizelConfig = clone(this._raw.config);
    const cfg: tables.IRawVizelConfig = JSON.parse(JSON.stringify(this._raw.config));
    cfg.view_class = this._raw.view_class;
    cfg.title = this.title;
    // if (!cfg.dataSource || !cfg.dataSource.dataset) {
    //   (cfg.dataSource || (cfg.dataSource = {})).dataset = this._dataset.getDatasetId();
    // }
    // delete cfg['frame'];
    return cfg;
  }

  public getDataset(): IDatasetModel {
    return this._dataset;
  }

  public getDashboard(): IDashboard {
    return this._dashboard;
  }

  public getFrame(): tables.IConfigFrame {
    return this._frame;
  }

  public getDescription(): string {
    return this.description;
  }

  public isContainer(): boolean {
    return this._raw.view_class === 'BIContainerDashView' || this.isRoot();
  }

  public isRoot(): boolean {
    return this._raw.view_class === 'BIRootDashView';
  }

  public addChild(child: Dashlet): void {
    this.children = [...this.children, child];
  }
}


export class Dashboard implements IDashboard {
  public axisId: string = 'dashboards';
  public id: string;
  public stateColor: string = '';
  public title: string;
  public config: any = {};
  public topic_id: number;
  public _is_editable: number;

  private _rootDashes: Dashlet[] = [];
  private _dashes: Dashlet[] = [];
  private _dashesById: { [dashId: string]: Dashlet } = {};
  private _dataset: IDatasetModel;

  public constructor(dataset: IDatasetModel, d: tables.IDashboardsItem) {
    this._dataset = dataset;
    this.id = d.id.toString();
    this.update(d);
  }

  public update(rawDashboard: tables.IDashboardsItem): void {
    const config: any = rawDashboard.config || {};
    this.title = rawDashboard.title || '';
    this.config = rawDashboard.config || {};
    this.topic_id = rawDashboard.topic_id;
    this._is_editable = (rawDashboard as any)?._is_editable || 0;
  }

  public addDash(raw: tables.IDashletsItem): void {
    const id: string = ('view_id' in raw) ? raw.view_id.toString() : raw.id.toString();
    if (id in this._dashesById) {
      this._dashesById[id].update(raw);
      return;
    }

    const dash: Dashlet = new Dashlet(this._dataset, this, raw);

    const parent: Dashlet = this.getDash(dash.parentId);
    if (parent) {
      parent.addChild(dash);
    }

    this._dashes.forEach((child: Dashlet) => {
      if (child.parentId === dash.id) dash.addChild(child);
    });    // dash order is unknown, so find children if any has already registered

    this._dashesById[id] = dash;
    this._dashes.push(dash);

    if (dash.isRoot() || dash.parentId === null) {
      this._rootDashes.push(dash);
    }
  }

  public getDash(dashId: string): Dashlet {
    return (dashId in this._dashesById) ? this._dashesById[dashId] : null;
  }

  public getDashes(): Dashlet[] {
    return this._dashes;
  }

  public getRootDashes(): Dashlet[] {
    return this._rootDashes;
  }
}


export class DashletsHelper implements IDashletsHelper {
  public dashboardTopics: tables.IDashboardTopic[] = [];
  public dashboards: Dashboard[] = [];
  private _dataset: IDatasetModel;

  public constructor(dataset: IDatasetModel, rawDashboards: tables.IDashboardsItem[], rawDashboardTopics: tables.IDashboardTopic[], rawDashlets: tables.IDashletsItem[]) {
    this._dataset = dataset;
    this.update(rawDashboards, rawDashboardTopics, rawDashlets);
  }

  public update(rawDashboards: tables.IDashboardsItem[], rawDashboardTopics: tables.IDashboardTopic[], rawDashlets: tables.IDashletsItem[]): void {
    this.dashboardTopics = rawDashboardTopics;                                                      // TODO: merge
    this.addDashboards(rawDashboards);
    this.addDashes(rawDashlets);
  }

  private _saveDashboard(dashboard: Dashboard): void {
    this.dashboards = [...this.dashboards, dashboard];
  }

  private _addDashboard(dashboardData: tables.IDashboardsItem): void {
    const id: string = dashboardData.id.toString();
    const dashboard = $eid(this.dashboards, id);
    if (dashboard) {
      dashboard.update(dashboardData);
    } else {
      this._saveDashboard(new Dashboard(this._dataset, dashboardData));
    }
  }

  private _addDashlet(rawDashlet: tables.IDashletsItem): void {
    const dashboard: Dashboard = this.getDashboard(rawDashlet.dashboard_id.toString());
    if (!dashboard) {
      console.error(`Not found dashboard with id=${rawDashlet.dashboard_id} from dash:view_id=${rawDashlet.view_id}`);
      throw new Error(`Not found dashboard with id=${rawDashlet.dashboard_id} from dash:view_id=${rawDashlet.view_id}`);
    }
    dashboard.addDash(rawDashlet);
  }

  private addDashboards(ds: tables.IDashboardsItem[]): void {
    ds.forEach((d: tables.IDashboardsItem) => this._addDashboard(d));
  }

  private addDashes(rawDashlets: tables.IDashletsItem[]): void {
    rawDashlets = rawDashlets.sort((d1, d2) => d1.id - d2.id);
    rawDashlets.forEach((d: tables.IDashletsItem) => this._addDashlet(d));
  }

  public getDash(id: string): Dashlet {
    for (let dashboard of this.dashboards) {
      const dash: Dashlet = dashboard.getDash(id);
      if (dash) {
        return dash;
      }
    }
    return null;
  }

  public getDashes(): Dashlet[] {
    return Array.prototype.concat.apply([], this.dashboards.map((dashboard: Dashboard) => dashboard.getDashes()));
  }

  public getDashboard(dashboardId: string): Dashboard {
    return $eid(this.dashboards, dashboardId);
  }
}
